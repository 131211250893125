import React from 'react';
import navLinks from '../content/navLinks';
import logo from '../assets/logos/Logo.svg';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { toggleModal } from '../actions/main';

class Menu extends React.Component {
  constructor() {
    super();
    this.state = {
      playMusic: false,
    };
  }
  toggleModal = () => {
    this.props.dispatch(toggleModal());
  };
  render() {
    return (
      <header className="menu">
        <Link to="/" className="link-logo">
          <img src={logo} alt="dakota bryant logo" className="logo" />
        </Link>

        <div className="links">
          {navLinks &&
            navLinks.map(link => {
              if (link.text === 'Contact') {
                return <p key={link.text} onClick={this.toggleModal}>{link.text}</p>;
              } else {
                return (
                  <Link key={link.text} to={link.path}>
                    {link.text}
                  </Link>
                );
              }
            })}
        </div>
      </header>
    );
  }
}

export default connect()(Menu);
