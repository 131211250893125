import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContactModal from './ContactModal';
import { toggleModal } from '../actions/main';
import posed from 'react-pose';

const PosedContact = posed.div({
  hidden: {
    opacity: 0, applyAtEnd: { display: "none" }
  },
  visible: {
    opacity: 1, applyAtStart: { display: "block" }
  },
})

class SiteContainer extends Component {
  handleClose = () => {
    this.props.dispatch(toggleModal());
  };
  render() {
    const { showModal = false, children } = this.props;
    return (
      <React.Fragment>
        <PosedContact pose={showModal ? 'visible' : 'hidden'} className="contact-modal-container" >
          <ContactModal onClose={this.handleClose} />
        </PosedContact>
        {children}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    showModal: state.mainReducer.showModal,
  };
};

export default connect(mapStateToProps)(SiteContainer);
